import React, { Component } from 'react';
import { StandardPageProps } from '../utils/Props';
import Layout from '../components/layout/Layout';
import SEO from '../components/layout/SEO';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { BsFillOctagonFill } from 'react-icons/bs';
import { ImCheckmark } from 'react-icons/im';
import { HiOutlineMail, HiOutlineGlobe } from 'react-icons/hi';
import classNames from 'classnames';
import {
  SiInstagram,
  SiPatreon,
  SiTwitch,
  SiTwitter,
  SiYoutube,
} from 'react-icons/si';

export default class AboutPage extends Component<
  AboutPageProps,
  AboutPageState
> {
  constructor(props: Readonly<AboutPageProps> | AboutPageProps) {
    super(props);
    this.state = {
      loadingChannelDetails: true,
      channelDetails: undefined,
      easterEgg: false,
      windowWidth: 0,
      windowHeight: 0,
    };
  }

  render() {
    const socialLinksClassNames = classNames(
      'flex justify-center items-center rounded-md relative',
      'text-gray-50',
      'transition opacity-70 border-opacity-50',
      'border border-gray-50',
      'outline-none ring-offset-4 ring-gray-50 ring-opacity-50 ring-offset-brand-jawoodle-dark',
      'focus:ring hover:opacity-100 focus:opacity-100 w-full px-4 py-3 text-lg'
    );

    const socialLinksIconClassNames = 'w-8 h-8';

    return (
      <Layout location={this.props.location}>
        <SEO
          title='About'
          description='Information about JaWoodle, a full time YouTuber from Melbourne, Australia'
          slug='/about'
        />
        <div className='flex flex-col justify-center items-center w-full max-w-4xl font-kaffeesatz'>
          <div className='w-full flex flex-col justify-center items-center space-y-6 md:flex-row md:space-x-6 md:space-y-0'>
            <div className='relative'>
              <StaticImage
                placeholder='none'
                width={200}
                layout='fixed'
                src='../assets/images/jawoodle-avatar-white-stroke.png'
                alt='JaWoodle'
              />
              <div className={'absolute bottom-0 right-0'}>
                <BsFillOctagonFill
                  className={'transform-gpu rotate-22.5 text-5xl'}
                />
                <BsFillOctagonFill
                  className={
                    'text-brand-twitch absolute transform-gpu top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 scale-90 rotate-22.5 text-5xl'
                  }
                />
                <ImCheckmark
                  className={
                    'text-2xl absolute transform-gpu top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
                  }
                />
              </div>
            </div>
            <div className='w-full bg-brand-jawoodle bg-opacity-60 text-xl px-4 py-4 rounded-lg shadow-lg'>
              <h1 className={'font-bold text-4xl pb-4'}>
                <span
                  className={
                    'relative inline-block md:transform-gpu md:-rotate-9 md:text-5xl'
                  }
                >
                  <span className={'relative z-10'}>G'day guys,</span>
                  <span
                    className={
                      'absolute inset-0 text-stroke-8 text-stroke-brand-jawoodle-dark text-brand-jawoodle-dark pointer-events-none select-none'
                    }
                    role={'presentation'}
                  >
                    G'day guys,
                  </span>
                </span>
                <span className={'md:hidden'}>&#32;</span>
                <span className={'md:block md:pl-28 pt-1'}>
                  <span className={'md:uppercase'}>m</span>y name's Josh but you
                  can call me JaWoodle!
                </span>
              </h1>

              <p>
                I'm a full time YouTuber and partnered Twitch variety streamer
                from Melbourne, Australia who plays a range of zombie survival
                games, such as 7 Days to Die. I take pride in offering my
                community a safe space filled with love, acceptance, coffee and
                froffies! We like to kill zombies, loot everything and dip our
                chips in salsa, but it doesn't always go to plan!
              </p>
            </div>
          </div>
          <ul
            className={
              'w-full mt-4 flex flex-col justify-start items-center space-y-2 md:flex-row md:space-x-4 md:space-y-0 text-lg'
            }
          >
            <li className={'flex items-center'}>
              <HiOutlineMail className={'mr-2 text-xl'} />
              <a className={'uppercase'} href='mailto:jawoodleyt@gmail.com'>
                jawoodleyt@gmail.com
              </a>
            </li>
            <li className={'flex items-center'}>
              <HiOutlineGlobe className={'mr-2 text-xl'} />
              <Link className={'uppercase'} to={'/'}>
                jawoodle.com
              </Link>
            </li>
          </ul>
          <div className='w-full mt-4 flex flex-col justify-center items-stretch space-y-6 md:flex-row md:space-x-6 md:space-y-0'>
            <div className='w-full bg-brand-jawoodle bg-opacity-60 text-lg px-4 py-4 rounded-lg shadow-lg flex flex-col'>
              <h2
                className={
                  'relative flex-none text-4xl md:text-5xl font-extrabold text-center'
                }
              >
                <span className={'relative z-10'}>YouTube</span>
                <span
                  className={
                    'absolute inset-0 text-stroke-8 text-stroke-brand-jawoodle-dark text-brand-jawoodle-dark pointer-events-none select-none'
                  }
                  role={'presentation'}
                >
                  YouTube
                </span>
              </h2>

              <ul className={'h-full mt-4 text-xl'}>
                <li>
                  Subscribers: <strong className={'font-bold'}>54.1K</strong>
                </li>
                <li>
                  New Subscribers: <strong className={'font-bold'}>900</strong>
                </li>
                <li>
                  Total Views: <strong className={'font-bold'}>17.6M</strong>
                </li>
                <li>
                  Avg. Daily Views:{' '}
                  <strong className={'font-bold'}>20.5K</strong>
                </li>
              </ul>

              <span className={'flex-none mt-2 text-base opacity-70'}>
                As of 2021-07-04
              </span>
            </div>
            <div className='w-full bg-brand-jawoodle bg-opacity-60 text-lg px-4 py-4 rounded-lg shadow-lg flex flex-col'>
              <h2
                className={
                  'relative flex-none text-4xl md:text-5xl font-extrabold text-center'
                }
              >
                <span className={'relative z-10'}>Twitch</span>
                <span
                  className={
                    'absolute inset-0 text-stroke-8 text-stroke-brand-jawoodle-dark text-brand-jawoodle-dark pointer-events-none select-none'
                  }
                  role={'presentation'}
                >
                  Twitch
                </span>
              </h2>

              <ul className={'h-full mt-4 text-xl'}>
                <li>
                  Followers: <strong className={'font-bold'}>5.1K</strong>
                </li>
                <li>
                  Total Views: <strong className={'font-bold'}>32.8K</strong>
                </li>
                <li>
                  Avg. Live Viewers:{' '}
                  <strong className={'font-bold'}>208</strong>
                </li>
                <li>
                  Total Subscribers:{' '}
                  <strong className={'font-bold'}>206</strong>
                </li>
              </ul>

              <span className={'flex-none mt-2 text-base opacity-70'}>
                As of 2021-07-04
              </span>
            </div>
            <div className='w-full bg-brand-jawoodle bg-opacity-60 text-lg px-4 py-4 rounded-lg shadow-lg flex flex-col'>
              <h2
                className={
                  'relative flex-none text-4xl md:text-5xl font-extrabold text-center'
                }
              >
                <span className={'relative z-10'}>Twitter</span>
                <span
                  className={
                    'absolute inset-0 text-stroke-8 text-stroke-brand-jawoodle-dark text-brand-jawoodle-dark pointer-events-none select-none'
                  }
                  role={'presentation'}
                >
                  Twitter
                </span>
              </h2>

              <ul className={'h-full mt-4 text-xl'}>
                <li>
                  Followers: <strong className={'font-bold'}>4K</strong>
                </li>
                <li>
                  New Followers: <strong className={'font-bold'}>120</strong>
                </li>
              </ul>

              <span className={'flex-none mt-2 text-base opacity-70'}>
                As of 2021-07-04
              </span>
            </div>
          </div>
          <ul className='mt-6 mb-4 flex flex-col md:flex-row w-full justify-center space-y-4 md:space-x-4 md:space-y-0'>
            <li className={'w-full'}>
              <a
                href='https://www.youtube.com/c/jawoodle'
                target='_blank'
                rel='noopener'
                className={socialLinksClassNames}
                title='YouTube'
                aria-label='YouTube'
              >
                <SiYoutube className={socialLinksIconClassNames} />
                <div className='ml-3 uppercase font-bold'>/jawoodle</div>
              </a>
            </li>
            <li className={'w-full'}>
              <a
                href='https://www.twitch.tv/jawoodle'
                target='_blank'
                rel='noopener'
                className={socialLinksClassNames}
                title='Twitch'
                aria-label='Twitch'
              >
                <SiTwitch className={socialLinksIconClassNames} />
                <div className='ml-3 uppercase font-bold'>/jawoodle</div>
              </a>
            </li>
            <li className={'w-full'}>
              <a
                href='https://twitter.com/jawoodle'
                target='_blank'
                rel='noopener'
                className={socialLinksClassNames}
                title='Twitter'
                aria-label='Twitter'
              >
                <SiTwitter className={socialLinksIconClassNames} />
                <div className='ml-3 uppercase font-bold'>@jawoodle</div>
              </a>
            </li>
            <li className={'w-full'}>
              <a
                href='https://www.instagram.com/jawoodle'
                target='_blank'
                rel='noopener'
                className={socialLinksClassNames}
                title='Instagram'
                aria-label='Instagram'
              >
                <SiInstagram className={socialLinksIconClassNames} />
                <div className='ml-3 uppercase font-bold'>@jawoodle</div>
              </a>
            </li>
            <li className={'w-full'}>
              <a
                href='https://www.patreon.com/jawoodle'
                target='_blank'
                rel='noopener'
                className={socialLinksClassNames}
                title='Patreon'
                aria-label='Patreon'
              >
                <SiPatreon className={socialLinksIconClassNames} />
                <div className='ml-3 uppercase font-bold'>/jawoodle</div>
              </a>
            </li>
          </ul>
        </div>
      </Layout>
    );
  }
}

interface AboutPageProps extends StandardPageProps {}

interface AboutPageState {}
